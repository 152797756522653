import React from "react";
import axios from "../redux/actions/AxiosInstance/authenticated";
import { useSelector } from "react-redux";
const Credits = () => {
  const { user } = useSelector((state) => state.auth);
  const [createAutoShareablePage, setCreateAutoShareablePage] = React.useState(
    user && user?.createAutoShareablePage
  );
  React.useEffect(() => {
    if (user?.id) {
      setCreateAutoShareablePage(user && user?.createAutoShareablePage);
    }
  }, [user]);
  const handleChange = async (e) => {
    console.log(e.target.checked);
    setCreateAutoShareablePage(e.target.checked);
    try {
      const response = await axios.post(`/user/change-preference`, {
        createAutoShareablePage: e.target.checked,
      });
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div className="px-4 md:px-28 py-10">
      <div className="flex flex-wrap -mx-4">
        <div className="w-full md:w-2/3 px-4 mb-4 md:mb-0">
          {/* <h2 className="text-2xl font-semibold mb-4">Credits</h2> */}
          <UsageDisplay user={user} />
        </div>
      </div>
    </div>
  );
};

export default Credits;
function UsageDisplay({ user }) {
  // Function to determine the color based on usage percentage
  const getColor = (usage, ceiling) => {
    const percentage = (usage / ceiling) * 100;
    if (percentage <= 25) return "bg-green-500"; // Green zone
    if (percentage <= 80) return "bg-blue-500"; // Blue zone
    return "bg-red-500"; // Red zone
  };

  // Format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <div className="flex flex-col gap-4 items-center mb-4">
      <div className="w-full">
        <div className="font-semibold mb-1">
          Transcription <span className="text-gray-500 text-xs">(in minutes)</span> (
          {user?.currentMonthUsage}/{user?.monthlyCreditCeiling})
        </div>
        <div className="w-full bg-gray-200 h-4 rounded-full">
          <div
            className={`${getColor(
              user?.currentMonthUsage,
              user?.monthlyCreditCeiling
            )} h-4 rounded-full`}
            style={{ width: `${(user?.currentMonthUsage / user?.monthlyCreditCeiling) * 100}%` }}
          ></div>
        </div>
      </div>
      <div className="w-full">
        <div className="font-semibold mb-1">
          Output Words ({user?.monthlyOutputWordsUsage}/{user?.monthlyOutputWordsCeiling})
        </div>
        <div className="w-full bg-gray-200 h-4 rounded-full">
          <div
            className={`${getColor(
              user?.monthlyOutputWordsUsage,
              user?.monthlyOutputWordsCeiling
            )} h-4 rounded-full`}
            style={{
              width: `${(user?.monthlyOutputWordsUsage / user?.monthlyOutputWordsCeiling) * 100}%`,
            }}
          ></div>
        </div>
      </div>
      <div className="w-full">
        <div className="font-semibold mb-1">
          Input Words ({user?.monthlyInputWordsUsage}/{user?.monthlyInputWordsCeiling})
        </div>
        <div className="w-full bg-gray-200 h-4 rounded-full">
          <div
            className={`${getColor(
              user?.monthlyInputWordsUsage,
              user?.monthlyInputWordsCeiling
            )} h-4 rounded-full`}
            style={{
              width: `${(user?.monthlyInputWordsUsage / user?.monthlyInputWordsCeiling) * 100}%`,
            }}
          ></div>
        </div>
      </div>
      {user?.currentMonthExpiresAt && (
        <div className="w-full mt-4">
          <div className="text-lg font-semibold">Usage Resets At:</div>
          <div className="text-md">{formatDate(user.currentMonthExpiresAt)}</div>
        </div>
      )}
    </div>
  );
}
