import { Routes, Route, Navigate } from "react-router-dom";
import {
  Authentication,
  ForgotPassword,
  ResetPassword,
  VerifyGoogleAuth,
  AutoLogin,
} from "./pages/Authentication";
import Index from "./pages/index.js";
import Uploader from "./pages/home/Uploader";
import Recorder from "./pages/home/Recorder";
import SocialMedia from "./pages/home/SocialMedia";
import Transcript from "./pages/home/Transcript";
import AddUseCase from "./pages/Admin/AddUseCase.js";
import PrivateRoute from "./components/PrivateRoutes";
import PaidRoutes from "./components/PaidRoutes";
import AdminRoutes from "./components/AdminRoutes.js";
import { Toaster } from "sonner";
import Templates from "./pages/home/Templates.js";
import TemplateEdit from "./pages/home/TemplateEdit.js";
import MyNotes from "./pages/home/MyNotes.js";
import Profile from "./pages/User/Profile.js";
import Billing from "./pages/Billing.js";
import LifetimeDeal from "./pages/LifetimeDeal.js";
import Share from "./pages/Share/Share.js";
import UseCaseTable from "./pages/Admin/UseCase.js";
import EditUseCase from "./pages/Admin/EditUseCase.js";
import Transformation from "./pages/home/Transformation.js";
import CheckOut from "./components/CheckOut.js";
import ViewTemplate from "./pages/home/ViewTemplate.js";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getUserInfo, getUseCases } from "./redux/actions/auth";
import { getLibrary } from "./redux/actions/library";

const App = (props) => {
  const { getUserInfo, getUseCases, getLibrary } = props;

  useEffect(() => {
    getUserInfo();
    getUseCases();
    getLibrary();
    // console.log(props);
  }, [getUserInfo, getUseCases, getLibrary]);
  return (
    <>
      <Toaster position="top-right" richColors closeButton />
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/signup" element={<Authentication />} />
        <Route path="/signin" element={<Authentication />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/auto-login" element={<AutoLogin />} />
        <Route path="/verify-google-auth" element={<VerifyGoogleAuth />} />
        <Route path="/share/:shareId?" element={<Share />} />
        <Route element={<PrivateRoute />}>
          <Route element={<Index />}>
            <Route element={<PaidRoutes />}>
              <Route path="/home/upload" element={<Uploader />} />
              <Route path="/home" element={<Uploader />} />
              <Route path="/home/record" element={<Recorder />} />
              <Route path="/home/social-media" element={<SocialMedia />} />
              <Route path="/home/transcript/:libId?" element={<Transcript />} />
              <Route path="/templates" element={<Templates />} />
              <Route path="/templates/edit" element={<TemplateEdit />} />
              <Route path="/notes" element={<MyNotes />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/transformation/:libId?" element={<Transformation />} />
              <Route path="/view-template-details/:id" element={<ViewTemplate />} />
            </Route>
            <Route path="/check-out-success" element={<CheckOut />} />
            <Route path="/billing" element={<Billing />} />
            <Route path="/lifetime-deal" element={<LifetimeDeal />} />
            {/* <Route path="/*"  element={<Index />} /> */}
            <Route element={<AdminRoutes />}>
              <Route path="/dvp/admin/usecase/add" element={<AddUseCase />} />
              <Route path="/dvp/admin/usecase" element={<UseCaseTable />} />
              <Route path="/dvp/admin/usecase/edit/:id" element={<EditUseCase />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { getUserInfo, getUseCases, getLibrary };

export default connect(mapStateToProps, mapDispatchToProps)(App);
