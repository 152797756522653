import { useLocation, Outlet, Navigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import BottomBar from "../components/BottomBar";
import { getUserInfo, getUseCases, markOnBoardingDone } from "../redux/actions/auth";
import { getLibrary } from "../redux/actions/library";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Widget } from "@typeform/embed-react";

export const Index = (props) => {
  const { getUserInfo, getUseCases, getLibrary } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const currentRoute = location.pathname.slice(1);
  const [sidebarClosingStatus, setSidebarClosingStatus] = React.useState(false);
  const { user } = useSelector((state) => state.auth);
  // useEffect(() => {
  //   getUserInfo();
  //   getUseCases();
  //   getLibrary();
  //   console.log(props);
  // }, [props]);
  if (user && !user.isOnboardingDone) {
    return (
      <Widget
        id="ASNcm2xQ"
        style={{ width: "100%", height: "92vh", marginTop: "0vh" }}
        className="my-form h-screen z-[1500]"
        hidden={{
          name: user.name,
          email: user.email,
        }}
        onSubmit={() => {
          dispatch(markOnBoardingDone());
        }}
      />
    );
  }
  return (
    <div className="flex font-[Red Hat Display] bg-[#fefefe]">
      <Sidebar
        sidebarClosingStatus={sidebarClosingStatus}
        setSidebarClosingStatus={setSidebarClosingStatus}
      />
      <div
        className={`w-full text-left  ${sidebarClosingStatus ? `md:ml-[240px]` : `md:ml-[5.2rem]`}`}
      >
        <div className="relative w-full min-h-screen overflow-y-auto">
          <Outlet />
        </div>
      </div>
      <BottomBar
        sidebarClosingStatus={sidebarClosingStatus}
        setSidebarClosingStatus={setSidebarClosingStatus}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { getUserInfo, getUseCases, getLibrary };

export default connect(mapStateToProps, mapDispatchToProps)(Index);
